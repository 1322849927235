.accompagnement {
    background-image: url("../../../public/assets/images-css/fond_technos_reverse.jpg");
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 3rem 0;
    height: 650px;
    &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        text-align: end;
        margin-top: 5rem;
        p {
            font-size: 1.2rem;
            width: 40%;
            transform: translateX(100px);
        }
    }
    &__titles {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        &__title {
            background-color: rgba(255, 255, 255, 0.774);
            border-radius: 15px 0 0 15px;
            padding: 0 1rem;
            text-align: center;
        }
        img {
            width: 30%;
            transform: translateX(-300px);
        }
    }
}
@media screen and (max-width:900px) {
    .accompagnement {
        flex-direction: column-reverse;
        height: 500px;
        &__card {
            width: 100%;
            margin: 0;
            p {
                transform: unset;
                padding: 0;
                width: 30%;
                margin-left: 3rem;
            }
        }
        &__titles {
            width: 100%;
            img {
                display: none;
            }
        }
    }
}
@media screen and (max-width:767px) {
    .accompagnement {
        padding: 0;
        &__card {
            margin-top: 2rem;
            align-items: center;
            p {
                font-size: 1rem;
                width: 50%;
                margin: 0;
                text-align: left;
                margin-left: 0;
                padding-right: 3rem;
            }
        }
        &__titles {
            &__title {
                h2 {
                    font-size: 1.3rem;
                }
            }
        }
    }
}