.actualities__grid {
    justify-content: center;
    width: inherit;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.actualities__title {
    margin-left: 2rem;
}

.actuality__card {
    width: 40%;
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    box-shadow: 10px 10px 1px rgba(24, 68, 162, 0.269);
    padding: 2rem;
    &__image {
        box-shadow: 15px 15px 1px rgba(97, 91, 88, 0.269);
        height: 200px;
        img {
            object-fit: cover;
            width: 300px;
            height: 200px;
        }
    }
    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        text-align: end;
        color: rgb(0, 0, 0);
        gap: 0;
        padding: 0;
        margin: 0;
        &:last-child {
            align-items: flex-end;
        }
    }
    & button {
        background-color: unset;
    }
    &:hover {
        cursor: pointer;
        animation: 0.2s ease-out forwards cardhover;
    }
    &__image:hover {
        animation: 0.2s ease-out forwards imghover;
    }
}

@keyframes imghover {
    from {
        box-shadow: 15px 15px 1px rgba(97, 91, 88, 0.269);
        transform: translate(0,0);
    }
    to {
        background-color: rgba(48, 48, 191, 0.582);
        box-shadow: 10px 10px 1px rgba(24, 68, 162, 0.269);
        transform: translate(5px,5px);
    }
}

@keyframes cardhover {
    from {
        box-shadow: 10px 10px 1px rgba(24, 68, 162, 0.269);
        transform: translate(0,0);
    }
    to {
        background-color: rgba(48, 48, 191, 0.582);
        box-shadow: 5px 5px 1px rgba(24, 68, 162, 0.269);
        transform: translate(5px,5px);
    }
}
// Actuality View

.actuality__modal {
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    min-height: 100%;
    z-index: 5000;
    gap: 2rem;
    background-color: #FEFEFE;
    justify-content: center;
    &__header {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__image {
            max-width: 800px;
            max-height: 700px;
            object-fit: contain;
        }
        &__title {
            width: 60%;
            font-size: 3rem;
            text-align: center;
            padding: 2rem;
            margin: 0;
        }
    }
    &__paragrafs {
        width: 50%;
    }
    &__span {
        width: 50%;
        padding: 2rem;
        text-align: end;
        font-style: italic;
    }
}

@media screen and (max-width:900px) {
    .actualities__grid{
        width: 100vw;
        padding: 2rem 0;
    }
    .actuality__card {
        margin-bottom: 0;
        align-items: center;
        width: 38%;
        &__image {
            height: 100px;
            img {
                width: 160px;
                height: 100px;
            }
        }
    }
    .actuality__modal {
        width: 100vw;
        &__header {
            &__image {
                padding: 1rem;
                max-width: 90%;
            }
            &__title {
                font-size: 3rem;
                padding: 1rem;
            }
        }
        &__paragrafs {
            transform: translateY(-50px);
        }
        &__span {
            padding-bottom: 2rem;
            padding-top: 0;
            padding-right: 8rem;
        }
        
    }
}

@media screen and (max-width:767px) {
    .actuality__card {
        width: 70%;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        &__text {
            align-items: center;
            text-align: center;
        }
        &__image {
            height: 150px;
            img {
                width: 250px;
                height: 150px;
            }
        }
    }
    .actuality__modal {
        &__header {
            &__image {
                width: 100vw;
                height: unset;
            }
            &__title {
                width: unset;
                font-size: 1.5rem;
                padding: 1rem;
                transform: none;
                color: black;

            }
        }
        &__paragrafs {
            width: 80%;
            transform: none;
        }
        &__span {
            padding: 1rem;
        }
    }
}