.enscnrs {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #0F1F3F;
    color: white;
    padding: 5rem 0;
    &__ens {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
        &__images {
            padding-left: 4rem;
            .image-ens {
                width: 300px;
                box-shadow: 40px 40px 1px rgba(0, 0, 0, 0.138);
            }
            .logo-ens {
                width: 40%;
                transform: translate(30px, 30px);
            }
        }
        &__title {
            display: flex;
            width: 50%;
            margin-right: 8%;
            h2 {
                text-align: end;
                font-size: 2.2rem;
            }
        }
    }

    &__cnrs {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
        &__text {
            margin-left: 8%;
            display: flex;
            width: 40%;
            align-items: flex-end;
            p {
                font-size: 1.3rem;
            }
        }
        &__images {
            display: flex;
            align-items: flex-start;
            margin-right: 4rem;
            margin-top: 3rem;
            .image-cnrs {
                width: 300px;
                box-shadow:-40px -40px 1px rgba(0, 0, 0, 0.138);
            }
            .logo-cnrs {
                width: 150px;
                transform: translate(-30px,-50px);
            }
        }
    }
}

@media screen and (max-width:900px) {
    .enscnrs {
        &__ens {
            &__images {
                .image-ens {
                    width: 200px;
                }
            }
        }
        &__cnrs {
            &__text {
                margin-top: 10rem;
                width: 50%;
                p {
                    padding: 0;
                }
            }
            &__images {
                .image-cnrs {
                    width: 200px;                    
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .enscnrs {
        margin: 0;
        padding-top: 3rem;
        padding-bottom: 0;
        &__ens {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            margin: 0;
            &__title {
                width: 90%;
                margin: 0;
                h2 {
                    font-size: 1.3rem;
                    text-align: center;
                }
            }
            &__images {
                display: flex;
                flex-direction: column;
                gap: 3rem;
                padding: 0;
                align-items: center;
                margin: 0;
                .image-ens {
                    width: 200px;
                }
                .logo-ens {
                    transform: unset;
                }
            }
        }
        &__cnrs {
            
            &__text {
                width: 100%;
                margin: 2rem 0;
                align-items: center;
                p {
                    font-size: 1rem;
                    padding: 0 2rem;
                    text-align: center;
                }
            }
            flex-direction: column;
            &__images {
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                gap:3rem;
                margin: 2rem 0;
               
                .image-cnrs {
                    width: 200px;
                    box-shadow: 40px 40px 1px rgba(0, 0, 0, 0.138);
                }
                .logo-cnrs {
                    transform: unset;
                    width: 100px;
                    z-index: 1;
                }
            }
        }
    }
}