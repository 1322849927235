.navBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: inherit;
  top: 0;
  background-color: #FEFEFE;
  z-index: 2000;
  &__logo {
    width: 100px;
    padding: 0.5rem;
  }

  &__links {
    display: flex;
    gap: 1rem;
    height: inherit;
    align-items: center;
    justify-self: flex-end;

    &:last-child {
      padding-right: 2rem;
    }
  }

  &__link {
    padding: 0 0.3rem;
    color: inherit;
    
  }

  &__burger {
    width: 40px;
    height: 40px;
    background: transparent;
    color: inherit;
  }
  .active {
    color: rgb(0, 85, 255);
  }
  &__socials {
    width: 30px;
  }
}

@media screen and (max-width: 1280px) {
  .navBar {
    height: 75px;
    &__logo {
      width: 60px;
    }
    &__links {
      position: fixed;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 0;
      left: 100vw;
      bottom: 0;
      height: 100vh;
      gap: 2rem;
      visibility: hidden;
      background-color: #fefefe;
      transition: all 0.8s ease-in-out;
      flex-direction: column;
      li {
        text-align: center;
      }
      li:last-child {
        padding-left: 0;
        border: none;
        text-align: center;
      }
    }
    &__link {
      display: block;
      padding: 0.5rem;
      transition: all 0.8s ease-in-out;
      font-size: 3vw;
    }
    &__burger {
      position: fixed;
      top: 1rem;
      right: 1rem;
    }
    &__burger:hover {
      cursor: pointer;
      
    }
    .burger-bar,
    .burger-bar::before,
    .burger-bar::after {
      display: block;
      width: 40px;
      height: 3px;
      position: relative;
      border-radius: 3px;
      background: black;
      transition: all .5s ease-in-out;
    }
    .burger-bar::before,
    .burger-bar::after {
      content: "";
      position: absolute;
      left: 0;
    }
    .burger-bar::before {
      transform: translateY(-12px);
    }
    .burger-bar::after {
      transform: translateY(12px);
    }
  }

  .show-nav .navBar__links {
    right: 0;
    left: 0;
    width: 100vw;
    visibility: visible;
  }
  .show-nav .burger-bar {
    background: transparent;
    width: 0;
  }
  .show-nav .burger-bar::before {
    transform: rotate(45deg);
  }
  .show-nav .burger-bar::after {
    transform: rotate(-45deg);
  }
  .navBar__link {
    transform: translateX(100vw);
  }
  .show-nav .navBar__link {
    transform: translateX(0);
  }

}

@media screen and (max-width: 768px) {
  .navBar {
    &__links {
      gap: 1.5rem;
    }
    &__link {
      font-size: 5vw;
    }
  }
}