.objectifs {
    background-color: #0F1F3F;
    display: flex;
    justify-content: center;
    align-items: center;
    &-text {
        color: whitesmoke;
        width: 40%;
        margin: 3rem 5rem;
    }
    &-pictures {
        width: 60%;
        margin: auto;
        display: flex;
        justify-content: center;
        &-card {
            background-color: white;
        }
    }
}

@media screen and (max-width: 767px) {
    .objectifs {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-top: 0;
        padding: 3rem 0;
        &-text {
            width: 80%;
            margin: 0;
            padding: 2rem;
        }
        &-pictures {
            width: 100%;
            margin: 1rem;
        }

    }
}