.nous {
    padding-bottom: 3rem;
    width: 100%;
    .employees__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3rem;
        padding: 1rem 0;
    }
}

// History

.history {
    padding: 3rem;
    background: url("../../../public/assets/images-css/avion-papier_129460-147.webp") no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    &__cartouche {
        background-color: #fefefebb;
        padding: 3rem;
        &__paragrafs {
            width: 65%;
        }
    }
}

// Employees

.employees {
    padding: 3rem;
    &__title {
        padding-bottom: 2rem;
    }
}

.employee__card {
    display: flex;
    align-items: center;
    width: 40%;
    border-left: 1px solid navy;
    padding-left: 1rem;
    &__title {
        align-self: flex-start;
        padding-top: 1rem;
        img {
            width: 150px;
            border-radius: 50%;
        }
        h3, h4 {
            margin: 0;
            
        }
        h3 {
            font-size: 1.3rem;
        }
        h4 {
            text-transform: uppercase;
        }
        &__socials {
            display: flex;
            justify-content: flex-end;
            img {
                width: 40px;
            }
        }
    }
    &__description {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        padding-top: 3rem;
        margin-left: 1rem;
        p {
            text-indent: 1rem;
            padding: 0 1rem;
        }
        
    }
}

@media screen and (max-width: 900px) {
    .employee__card {
        width: 90%;
    }
    .history {
        padding: 1.5rem;
        &__cartouche{
            padding: 1.5rem;
                &__paragrafs {
                    width: 100%;
                }
        }
    }
}

@media screen and (max-width:767px) {
    .employees {
        padding: 1rem;
    }
    .employee__card {
        flex-direction: column;
        &__title {
           width: 60%;
        }
        &__descripton {
            margin-left: 0;
            p {
                padding: 0;
            }
        }
    }
    .history {
        padding: 1rem;
        &__cartouche {
            padding: 1rem;
        }
    }
}
