.home-fondamentaux {
    width: 100%;
    background-image: url("../../../public/assets/images-css/fond_technos.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 720px;
    &__title {
        width: 70%;
        margin-top: 3rem;
        background-color: #1eb3f3ba;
        padding: 5px 0;
        border-radius: 0 15px 15px 0;
        h2 {
            padding-left: 2rem;
        }
    }
    &__text {
        width: 25%;
        margin-top: 5rem;
        margin-left: 4rem;
        p {
            font-size: 1.3rem;
            text-align: end;
        }
    }
}
.home-fondamentaux2 {
    width: 100%;
    background-image: url("../../../public/assets/images-css/fond_technos_reverse.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 700px;
    &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    &__title {
        width: 60%;
        background-color: rgba(255, 255, 255, 0.774);
        padding: 5px 0;
        border-radius: 15px 0 0 15px;
        transform: translateY(-50%);
        h2 {
            text-align: center;
        }
    }
    &__content {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 100%;
        &__text {
            width: 25%;
            margin-bottom: 13rem;
            margin-left: 4rem;
            text-align: end;
            p {
                font-size: 1.3rem;
            }
        }
        &__card {
            width: 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding: 1rem 2rem;
            background-color: rgb(255, 255, 255);
            border-radius: 15px;
            margin-bottom: 7rem;
            margin-right: 11rem;
            img {
                width: 150px;
            }
        }
    }
}
@media screen and (max-width:900px) {
    .home-fondamentaux {
        &__title {
            width: 90%;
        }
        &__text {
            width: 40%;
            margin-left: 3rem;
        }
    }
    .home-fondamentaux2 {
        &__title {
            width: 70%;
        }
        &__content {
            &__text {
                width: 40%;
                margin-bottom: 14rem;
            }
            &__card {
                width: 45%;
                margin-right: 1rem;
                margin-bottom: 3rem;
                padding: 1rem;
            }
        }

    }
}

@media screen and (max-width:767px) {
    .home-fondamentaux {
        &__title {
            width: 95%;
            h2 {
                font-size: 1rem;
            }
        }
        &__text {
            margin: 0;
            padding: 0;
            margin-top: 5rem;
            width: 80%;
            align-self: flex-end;
            p {
                font-size: 1rem;
            }
        }
    }
    .home-fondamentaux2 {
        &__title {
            width: 95%;
            h2 {
                font-size: 1rem;
            }
        }
        &__content {
            flex-direction: column;
            &__text {
                width: 80%;
                margin-bottom: 0;
                margin-top: 5rem;
                p{
                    font-size: 1rem;
                }
            }
            &__card {
                width: 80%;
                margin-bottom: 3rem;
                h3 {
                    font-size: 1.2rem;
                }
            }
        }
    }
}