.easy-fluid {
    margin: 2rem;
    margin-top: 4rem;
    border: 5px solid #0F1F3F;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
        border: 5px solid #0F1F3F;
        border-radius: 15px;
        padding: 0 1rem;
        background-color: #fefefe;
        transform: translateY(-50%);
        h2 {
            font-size: 2rem;
        }
    }
    p {
        font-size: 1.2rem;
    }
    h4 {
        font-size: 1rem;
    }
    &__intro {
        width: 80%;
    }
    &__presentation {
        display: flex;
        justify-content: center;
        gap: 2rem;
        padding: 2rem 0;
        align-items: flex-end;
        img {
            width: 400px;
        }
        p {
            width: 30%;
            margin-bottom: 4rem;
        }
    }
    &__logos {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 3rem;
        margin: 2rem 0;
    }
    &__footer {
        width: 100%;
        display: flex;
        margin: 3rem;
        justify-content: center;
        gap: 1rem;
        p, li {
            font-size: 0.8rem;
            list-style: square;
        }
        & > div {
            width: 25%;
            padding-left: 2rem;
            display: flex;
            flex-direction: column;
        }
        &__studies {
           border-left: 2px solid black;
           border-right: 2px solid black;
        }
    }
}

@media screen and (max-width:767px) {
    .easy-fluid {
        margin: 2rem 1rem;
        &__title {
            padding: 0 0.3rem;
            h2 {
                font-size: 1.3rem;
                text-align: center;
            }
        }
        p {
            font-size: 1rem;
            padding: 0;
        }
        &__intro {
            width: 90%;
        }
        &__presentation {
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            img {
                width: 250px;
            }
            p {
                width: 90%;
                padding: 0;
                text-align: center;
                margin: 0;
            }
        }
        &__logos {
            flex-direction: column;
            align-items: center;
            img {
                width: 250px;
            }
        }
        &__footer {
            flex-direction: column;
            align-items: center;
            p, li {
                font-size: 0.7rem;
            }
            & > div {
                width: 80%;
                padding: 0;
            }
            &__studies {
                border: unset;
                border-top: 2px solid black;
                border-bottom: 2px solid black;
            }
            li:last-child {
                padding-bottom: 1rem;
            }
        }
    }
}