.revolution {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #0F1F3F;
    padding-top: 2rem;
    padding-bottom: 5rem;
    color: white;
    &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 3rem 0;
        background-image: linear-gradient(0.5turn, #0F1F3F, #0F1F3Fc4, #0F1F3F), url("../../../public/assets/images-css/tableau_inverse.jpg");
        background-size: contain;
        p {
            width: 70%;
            font-size: 1.3rem;
        }
        h2 {
            font-size: 2rem;
        }
    }
    h3 {
        padding: 2rem 0;
    }
    &__picture {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 5rem;
        padding: 1rem 0;
        img {
            width: 350px;
            box-shadow: 40px 40px 1px rgba(0, 0, 0, 0.138);
        }
        p {
            width: 30%;
            font-size: 1rem;
        }
    }
}
@media screen and (max-width:900px) {
    .revolution {
        &__card {
            p {
                width: 90%;
            }
        }
        h3 {
            text-align: center;
        }
    }
}

@media screen and (max-width:767px) {
    .revolution {
        gap: 3rem;
        &__card {
            padding: 4rem 0;
            p {
                font-size: 1rem;
                padding: 0;
            }
            h2 {
                font-size: 1.5rem;
            }
        }
        &__picture {
            flex-direction: column;
            align-items: center;
            gap: 3rem;
            img {
                width: 250px;
            }
            p {
                width: 70%;
            }
        }
    }
}