.entreprise {
    display: flex;
    flex-direction: row;
    background-color: #0F1F3F;
    align-items: center;
    .nav-background {
        width: 100%;
        background-color: whitesmoke;
        height: 11rem;
    }
    .entreprise-card {
        width: 40%;
        color: white;
        .entreprise-text {
            margin: 5rem;
        }
    }
    .entreprise-picture {
        width: 60%;
        height: 400px;
        margin: 3rem;
        background-image: url("../../../public/assets/images-css/Airbus-nuages.jpg");
        background-size: cover;
        display: flex;
        img {
            width: 8rem;
            margin: auto;
            padding: 10px;
            background-color: white;
        }
    }
}

@media screen and (max-width: 900px) {
    .entreprise {
        flex-direction: column;
        padding-bottom: 0;
        .entreprise-card {
            width: 100%;
            .entreprise-text {
                margin: 4rem 3rem;
            }
        }
        .entreprise-picture {
            width: 100vw;
            height: 400px;
            margin: 0;
            img {
                width: 3rem;
            }
        }
    }
}