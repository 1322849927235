.footer {
    background-color: #0F1F3F;
    color: whitesmoke;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem 0;
    &-flyn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
    }
    &-cookies {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            width: 60%;
            padding: 0.5rem;
            border: 1px solid whitesmoke;
        }
    }
    &-dev {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            margin: 0.2rem;
        }
    }

}

@media screen and (max-width:1280px) {
    .footer{
        width: 100%;
        font-size: 0.8rem;
        &-cookies {
            display: none;
        }
    }
}

@media screen and (max-width:767px) {
    .footer {
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
        font-size: 0.5rem;
        &-flyn {
            width: 50%;
        }
        &-cookies {
            width: 100%;
            p {
                padding: 1rem;
            }
        }
        &-dev {
            width: 50%;
        }
    }
}