.btn-easy-fluid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0F1F3F;
    h3 {
        font-size: 0.8rem;
        margin: 0;
        line-height: 1rem;
        transform: translateY(15px);
    }
    h1 {
        border: 2px solid #0F1F3F;
        padding: 0 0.5rem;
        border-radius: 10px;
    }
}

.btn-front {
    flex-direction: row;
}