@font-face {
    font-family: "jura";
    src: url("../../public/assets/fonts/Jura-Regular.ttf");
}

* {
    list-style: none;
}

body {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1280px;
    min-height: 100vh;
    margin: auto;
    box-shadow: 3px 0px 30px rgba(0, 0, 0, 0.3);
    
    main, header {
        flex-grow: 1;
    }

    main {
        margin-top: 90px;
    }
}

.text-cartouche {
    font-family: open-sans, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
}

.titre-cartouche {
    font-weight: 700;
    font-size: 1.5rem;
    font-family: open-sans, sans-serif;
}

.title-card {
    font-weight: 700;
    font-size: 1.5rem;
    font-family: open-sans, sans-serif;
    margin: 0;
}

.text-card {
    font-family: open-sans, sans-serif;
    font-size: 13px;
}

.text-links {
    font-size: 15px;
    font-family: jura, sans-serif;
    font-weight: 600;
    
}

.header-btn {
    font-family: jura;
    color: #0c265b
}

.text-footer {
    font-family: jura;
}

.hide {
    display: none;
}

.x {
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 5500;
    background: transparent;
    cursor: pointer;
    top: 2rem;
    right: 2rem;
    &-bar,
    &-bar::before {
        display: block;
        width: 50px;
        height: 4px;
        position: relative;
        border-radius: 3px;
        background: rgb(0, 0, 0);
        animation: all 1s ease-in-out;
    }
    &-bar {
        transform: rotate(45deg);
    }
    &-bar::before {
        content: "";
        position: absolute;
        transform: rotate(90deg);
    }
    &-bar:hover {
        transform: rotate(45deg);
    }
}


@media screen and (max-width: 1280px) {
    .App {
        width: 100%;
        main {
            margin-top: 75px;
            flex-grow: unset;
        }
    }
    .titre-cartouche {
        font-size: 1.5rem;
    }
    .title-card {
        font-size: 1rem;
    }
    .text-card{
        font-size: 12px;
    }
    .x {
        top: 2rem;
        right: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .text-cartouche {
        font-size: 1rem;
    }
}