.technologies {
    background-image: url("../../../public/assets/images-css/fond_technos.jpg");
    background-size: cover;
    width: 100%;
    padding-top: 7rem;
    display: flex;
    &-card {
        height: 100%;
        width: 420px;
        margin-left: 6rem;
        background-color: rgba(255, 255, 255, 0.80);
        &-logo {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        &-text {
            margin: 2rem;
        }
    }
}

@media screen and (max-width: 900px) {
    .technologies {
        padding-top: 1rem;
        &-card {
            &-text {
                padding: 2rem 0;
            }
            &-picture {
                overflow-y: hidden;
            }
            
        }
    }
}

@media screen and (max-width:767px) {
    .technologies {
        align-items: center;
        padding-top: 0;
        &-card {
            margin: 1rem;
            &-text {
                padding: 2rem 0;
            }
            &-picture {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 2rem;
                img {
                    width: 90%;
                }
            }
        }
    }
}