.recrutement {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: linear-gradient(120deg, rgb(160, 222, 219),rgb(3, 165, 209));
    &__title {
        margin: 2rem 5rem;
    }
    &__offers {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;
        justify-content: center;
    }
}
.offer__card {
    width: 50%;
    padding: 2rem;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    &__footer {
        font-size: .8rem;
        padding-left: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    &__button {
        text-decoration: none;
        padding: 0.5rem;
        color: whitesmoke;
        border-radius: 5px;
        background-color: #0F1F3F;
        text-align: center;
    }
}

@media screen and (max-width: 900px) {
    .recrutement {
        margin-top: 5rem;
        
    }
    .offer__card {
        &__footer {
            padding-left: 1rem;
            padding-bottom: 1rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .recrutement {
        &__title {
            margin: 2rem 1rem;
        }
    }
    .offer__card {
        width: 90%;
        padding: 1rem;
    }
}

