.etudes__header {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-image: url("../../../public/assets/images-css/fond_etudes.png");  
    background-size: cover;
    justify-content: space-between;
    align-items: center;
    &__title {
        width: 40%;
        padding: 3rem;
        .title {
            text-align: center;
            font-size: 5rem;
            color: whitesmoke;
            text-transform: uppercase;
        }
    }
}

.etudes__bande {
    margin-top: 5px;
    display: flex;
    width: 100%;
    img {
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .etudes__header {
        
        &__title {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 0;
            .title {
                font-size: 4rem;
                
            }
        }
    }
}