.contact {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #99D8F4;
            line-height: 0.01rem;
            padding: 1rem 3rem;
        }
        &__inputs {
            width: 100%;
        }
    }
    
}

// FORMULAIRE

.contact-form {
    display: flex;
    margin: 2rem 0;
    flex-direction: column;
    align-items: center;
    .form-fields {
        display: flex;
        flex-direction: column;
        height: 60px;
        width: 100%;
    }
    input {
        border: 1px solid #99D8F4;
        border-radius: 3px;
        background-color: #99d9f442;
    }
    .field-message {
        height: 100px;
        textarea {
            border: 1px solid #99D8F4;
            border-radius: 3px;
            background-color: #99d9f442;
        }
    }
    .errors{
        color: red;
        font-size: 0.8rem;
        font-style: italic;
    }

    .btn-submit {
        width: 7rem;
        background-color: #0F1F3F;
        padding: 0.5rem 0;
        color: whitesmoke;
        border-radius: 5px;
    }
}

// MAP

.leaflet-container {
    height: 350px;
    width: 500px;
}

.map__container {
    margin-bottom: 2rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    &__text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        padding-bottom: 2rem;
        h1 {
            margin: 0.5rem 0;
            padding: 0;
            font-size: 2rem;
        }
        p {
            padding: 0;
            margin: 0;
            line-height: 1.5rem;
        }
    }
}

.popup {
    &__title {
        display: flex;
        justify-content: center;
        img {
            padding: 1rem;
            width: 120px;
        }
    }
}

@media screen and (max-width: 900px){
    .map__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .contact {
        &__form {
            &__title {
                justify-content: center;
                font-size: 1rem;
            }
        }
    }
    .contactForm {
        padding-top: 5rem;
        &-text {
            &-title{
                padding-top: 1rem;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .contact {
        gap: 2rem;
        flex-direction: column;
        &__form {
            &__title {
                padding: 0 1rem;
                width: 80vw;
            }
        }
    }
    .leaflet-container {
        width: 350px;
        height: 350px;
    }
}