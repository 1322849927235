header {
    width: 100%;
    height: 768px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 110px;
}
.carousel {
  position: absolute;
  width: 1280px;
  height: 768px;
  z-index: -10;
}

.baseline {
    margin-left: 100px;
    padding-bottom: 10rem;
}

.header-down {
    display: flex;
    justify-content: space-between;
    margin: 0 6rem;
    align-items: flex-end;
    .card-revolution {
        width: 420px;
        color: white;
        background-color: #14387F;
        border-radius: 5px 5px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        h2 {
            font-weight: 700;
            color: white;
        }
    }
}

.container-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  .container-image {
    display: block;
    border: #14387F solid 5px;
    border-radius: 10px;
    transform: translateY(1.5rem);
    .image-ingenieries {
      width: 18rem;
    }
  }
  .overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #4576d75c;
  }
}



@import url('https://fonts.googleapis.com/css?family=Mukta:700');

$bg: #000000;
$white: #fff;
$black: #14387F;

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-family: open-sans, sans-serif;
    background: #fff ;
    border-radius: 1.625rem;
  &.ingenieries {
    width: 22rem;
    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $black;
      border-radius: 1.625rem;
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: absolute;
      font-size: 23px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $black;
      font-weight: 800;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &:hover {
    .circle {
      width: 100%;
      .icon {
        &.arrow {
        background: $white;
        transform: translate(1rem, 0);
        }
      }
    }
    .button-text {
      color: $white;
    }
  }
}

@media screen and (max-width: 1280px) {
  header {
    width: 100%;
    justify-content: end;
    margin-top: 100px;
  }
  .carousel {
    position: absolute;
    width: 100%;
    z-index: -10;
  }
  .baseline {
    margin-left: 3rem;
    padding-bottom: 7rem;
    img {
      width: 30%;
    }
  }
  .header-down {
    margin: 0 2rem;
    justify-content: space-around;
    .card-revolution {
      width: 300px;
      height: 240px;
      h2 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }

  button {
    &.ingenieries {
      width: 15rem;
      .circle {
        width: 2rem;
        height: 2rem;
        .icon {
          &.arrow {
            left: 0.22rem;
          }
        }
      }
      .button-text {
        font-size: 15px;
        line-height: 0.5;
      }
    }
    
  }
  .container-button {
    .container-image {
      .image-ingenieries {
        width: 12rem;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .carousel {
    width: 100vw;
  }
  .baseline {
    padding-bottom: 2rem;
  }
  .header-down {
    .card-revolution {
      height: 300px;
    }
  }
}

@media screen and (max-width: 767px) {
  header {
    height: unset;
    padding: 3rem 0;
    margin-top:3rem;
    background-image: url("../../../public/assets/images-css/Fond-HomeHeader_mobile.jpg");
  }
  .image-fond-header {
    display: none;
  }
  .baseline {
    img {
      width: 200px;
    }
  }
  .header-down {
    flex-direction: column;
    align-items: center;
    justify-content: space space-between;
    gap: 2rem;
    flex-grow: 1;
    .card-revolution {
      border-radius: 10px;
      height: inherit;
      padding-bottom: 1rem;
    }
  }
  .container-button {
    transform: translateY(2rem);
  }
}