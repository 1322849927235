.realisations__grid {
    justify-content: center;
    width: inherit;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.video__card {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
    box-shadow: 10px 10px 1px rgba(24, 68, 162, 0.269);
    padding: 2rem;
    h3 {
        text-align: end;
    }
}

@media screen and (max-width:767px) {
    .video__card {
        width: 95%;
    }
}